<template>
    <div>

        <v-row>
            <v-col>
                <v-radio-group
                v-model="values.category"
                mandatory
                label="Tipo de cuenta"
                data-cy="category-input"
            >
                <v-row >
                    <v-col cols="6" sm="3" class="py-2">
                        <radioItemForm
                            label="Crédito"
                            valueInput="Credit"
                            title=""
                            data-cy="category-input-credit-value"
                        >
                        </radioItemForm>
                        
                        
                    </v-col>
                    <v-col cols="6" sm="3" class="py-2">
                        <radioItemForm
                            label="Débito"
                            valueInput="Debit"
                            title=""
                            data-cy="category-input-debit-value"
                        >
                        </radioItemForm>
                        
                        
                    </v-col>
                </v-row>
            </v-radio-group>
            </v-col>
            
        </v-row>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Bancos"
                    :loading="loadingBanks"
                    :valueInput="values.bank_id"
                    :rule="rules.required"
                    :items="serverItems.banks"
                    :errorMessages="serverErrors.bank_id"
                    @valueChange="(v) => {values.bank_id = v; delete serverErrors.bank_id;delete serverErrors.catalog; delete serverErrors.bank_id}"
                    data-cy="bank_id-input"
                    :disabled="edit"
                ></autocompleteOneForm>
            </v-col>
        </v-row>
       
        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.alias_name = v; delete serverErrors.alias_name; delete serverErrors.slug}" 
                    :valueInput="values.alias_name" 
                    :rule="[...rules.required]" 
                    label="Alias"
                    :errorMessages="serverErrors.alias_name||serverErrors.slug"
                    data-cy="alias_name-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.account_number = v; delete serverErrors.account_number}"
                    :valueInput="values.account_number"
                    :rule="[...rules.numbers]"
                    label="Número de cuenta"
                    :errorMessages="serverErrors.account_number"
                    data-cy="account_number-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm
                    @valueChange="(v) => {values.initial_balance = v}" 
                    :valueInput="values.initial_balance" 
                    :rule="rules.cost" 
                    label="Saldo inicial"
                    :errorMessages="serverErrors.initial_balance"
                    :prefix="(values.initial_balance == '' || values.initial_balance == null ) ? '' : '$'"
                    data-cy="initial_balance-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col  class="py-2">
                <datePickerForm
                    label="Fecha de saldo inicial"
                    :valueInput="values.initial_balance_date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.initial_balance_date = v; delete serverErrors['initial_balance_date']}"
                    :errorMessages="serverErrors['initial_balance_date']"
                    data-cy="initial_balance_date-input"
                ></datePickerForm>
            </v-col>
        </v-row>

       

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
            loadingBanks: false,

            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                numbers: [
                    v => !!v || 'El campo es requerido',
                    v => /^[0-9]*$/.test(v) || 'El campo sólo debe contener números',
                    // v => (v.length == 0 || v.length == 10) || 'El campo debe contener 10 digitos'
                ],
                cost: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],
                
            },

            serverItems:{
                banks: []
            }
        }
    },
   
    mounted(){
        this.getBanks();
    },
    methods: {
        getBanks()
        {
            this.loadingBanks = true;
            this.$api.catalog.getBanks()
                .then((resp) => {
                    this.serverItems.banks = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingBanks = false;
                })
            ;
        },
       
    }
}
</script>

<style>

</style>